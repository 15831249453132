import React from "react";

import telegram from "../../assets/icons/tgFooter.svg";
import vk from "../../assets/icons/vkFooter.svg";
import skype from "../../assets/icons/skypeFooter.svg";
import mail from "../../assets/icons/mailFooter.svg";
import whatsApp from "../../assets/icons/whatsappFooter.svg";
import logoFooter from "../../assets/icons/logoFooter.svg";

import "./footer.scss";

const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="footer-box">
        <div className="footer-box__desc">
          <div className="footer-box__desc-text">
            <img className="image-logoFooter" src={logoFooter} alt="#" />
            <p className="base-text">
              Профессиональный коллектив по разработке веб- и моб- приложений,
              систем управления, сайтов, игр. Оказываем полный комплекс услуг от
              прототипирования до SEO-оптимизации.
            </p>
          </div>
          <div className="footer-box__desc-network">
            <div className="network-images">
              <a href="http://t.me/webteamstorm">
                <img className="image-network" src={telegram} alt="#" />
              </a>
              <a href="https://vk.com/webteamstorm">
                <img className="image-network" src={vk} alt="#" />
              </a>
              <a href="skype:live:severtaing">
                <img className="image-network" src={skype} alt="#" />
              </a>
              <a href="mailto:webteamstorm@gmail.com">
                <img className="image-network" src={mail} alt="#" />
              </a>
              <a href="https://wa.me/+79289566198">
                <img className="image-network" src={whatsApp} alt="#" />
              </a>
            </div>
            <p className="base-text">Режим работы: пн-пт 09:00-18:00</p>
            <p className="base-text">
              Номер телефона: <a href="tel:+79289566198">+7 (928) 956-61-98</a>
            </p>
            <p className="base-text">
              Email:{" "}
              <a href="mailto:webteamstorm@gmail.com">webteamstorm@gmail.com</a>
            </p>
          </div>
        </div>
        <div className="footer-box__nav">
          <span className="nav-text">
            Severtain © 2017-2024 | Все права защищены
          </span>
          <nav>
            <a
              className="nav-text"
              href="https://webteamstorm.ru/projects/mobile-applications/"
            >
              Портфолио
            </a>
            <a className="nav-text" href="https://webteamstorm.ru/docs/">
              Документация
            </a>
            <a className="nav-text" href="https://webteamstorm.ru/team/">
              О нас
            </a>
            <a
              className="nav-text"
              href="https://webteamstorm.ru/docs/agreements/"
            >
              Пользовательские соглашения
            </a>
          </nav>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
