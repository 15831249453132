import React, {useEffect, useState} from "react";

import logoBgMenu from "../../assets/icons/logoBgMenu.svg";
import bgMenu from "../../assets/icons/bgMenu.png";
import tg from "../../assets/icons/tgForm.svg";
import vk from "../../assets/icons/vkHeader.svg";
import skype from "../../assets/icons/skypeForm.svg";
import mail from "../../assets/icons/mailHeader.svg";
import whatsap from "../../assets/icons/whatsappForm.svg";

import "./headerMenu.scss";
import dropdownArrow from "../../assets/icons/dropdownArrow.svg";
import Dropdown from "../Dropdown/Dropdown";
import BaseButton from "../../ui/BaseButton/BaseButton";

const HeaderMenu = ({ status, setModal, statusMenu }) => {
  const [dropdown, setDropdown] = useState(false);

  function toggle() {
    if (dropdown == true) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  }

  return (
      <div className={status ? "headerMenu showMenu" : "headerMenu"}>
        <img className="img-logoBgMenu" src={logoBgMenu} alt="#"/>
        <nav className="headerMenu-nav">
          <div className="headerMenu-dropdown">
            <div className="headerMenu-dropdown__head" onClick={toggle}>
              <p className="base-text">Услуги</p>
              <img
                  className={dropdown ? "activeArrow" : ""}
                  src={dropdownArrow}
                  alt="#"
              />
            </div>
            <div
                className={
                  dropdown
                      ? "headerMenu-dropdown__body headerMenu-dropdown--active"
                      : "headerMenu-dropdown__body"
                }
            >
              <a
                  className="base-text"
                  href="https://lp.webteamstorm.ru/"
              >
                Разработка лендинга
              </a>
              <a
                  className="base-text"
                  href="https://webteamstorm.ru/services/shop/"
              >
                Разработка интернет-магазина
              </a>
            </div>
          </div>
        <a className="base-text" href="#solutions" onClick={() => {
         statusMenu(false);
         }}>
           Решения
         </a>

         <a className="base-text" href="#portfolio"
            onClick={() => {
                statusMenu(false);
            }}>
           Портфолио
         </a>
         <a className="base-text" href="#stages" onClick={() => {
             statusMenu(false);
         }}>
           Этапы
         </a>

         <a className="base-text" href="#calculator"  onClick={() => {
             statusMenu(false);
         }}>
           Калькулятор
         </a>
          <a
              className="base-text"
              href="#whyWe"
              onClick={() => {
                statusMenu(false);
              }}
          >
            Преимущества
          </a>
        <a className="base-text" href="#contacts" onClick={() => {
            statusMenu(false);
        }}>
            Контакты
        </a>
        </nav>
        <BaseButton
            styles={"headerMenu-btn"}
            onClick={() => {
              setModal(true);
              statusMenu(false);
            }}
        >
          Связаться с нами
        </BaseButton>
        <div className="headerMenu-bottom">
          <div className="headerMenu-bottom__network">
            <a href="http://t.me/webteamstorm">
              <img className="img-network" src={tg} alt="#"/>
            </a>
            <a href="https://vk.com/webteamstorm">
              <img className="img-network" src={vk} alt="#"/>
            </a>
            <a href="skype:live:severtaing">
              <img className="img-network" src={skype} alt="#"/>
            </a>
            <a href="mailto:webteamstorm@gmail.com">
              <img className="img-network" src={mail} alt="#"/>
            </a>
            <a href="https://wa.me/+79289566198">
              <img className="img-network" src={whatsap} alt="#"/>
            </a>
          </div>
            <div className={'headerMenu-bottom__contacts'}>
                <span>Режим работы:</span>
                <p>пн-пт 09:00-18:00</p>
            </div>
            <div className={'headerMenu-bottom__contacts'}>
                <span>Номер телефона: </span>
                <a className="mailHeader" href="tel:+79289566198">
                    +7 (928) 956-61-98
                </a>
            </div>
            <p className={'headerMenu-bottom__contacts'}>
                Email:
                <a className="mailHeader" href="mailto:webteamstorm@gmail.com">
                    webteamstorm@gmail.com
            </a>
          </p>
        </div>
        <img className="img-BgMenu" src={bgMenu} alt="#"/>
      </div>
  );
};

export default HeaderMenu;
