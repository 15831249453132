import React, { useState } from "react";

import mainBgApp from "../../assets/images/mainBackground.png";
import mainBgAppWebp from "../../assets/images/mainBackground.webp";
import mainBgPhone from "../../assets/images/mainPhonesBacground.png";
import phones from "../../assets/images/mainPhones.png";
import phonesWebp from "../../assets/images/mainPhones.webp";
import BaseButton from "../../ui/BaseButton/BaseButton";

import "./preview.scss";
import ModalСonnection from "../../components/ModalСonnection/ModalСonnection";

const Preview = (props) => {
  const [modal, setModal] = useState(false);
  return (
    <section className="preview">
      <div className="preview-box">
        <div className="preview-box__app">
          <div className="preview-box__app-item">
            <div className="app-item__head">
              <h1 className="base-title blackTitle">
                Разработка мобильных приложений
                нативные и кроссплатформенные решения <span>под iOS и Android</span>
              </h1>
            </div>
            <BaseButton
                styles={"button-preview"}
                onClick={() => {
                  setModal(true);
                }}
            >
              Заказать приложение
            </BaseButton>
          </div>

          <picture>
            <source srcSet={mainBgAppWebp} type={'image/webp'}/>
            <img
                className="image-bgApp"
                src={mainBgApp}
                alt="#"
            />
          </picture>

          {/*<div className="preview-box__app-body">*/}
          {/*  <p>*/}
          {/*    Более <span>7</span> лет на рынке*/}
          {/*  </p>*/}
          {/*  <p>*/}
          {/*    <span> 100+</span> реализованных проектов*/}
          {/*  </p>*/}
          {/*  <p>*/}
          {/*    <span>10%</span> минимальный рост выручки{" "}*/}
          {/*  </p>*/}
          {/*</div>*/}

          <div className="preview-box__app-body">
            <p>
              Индивидуальный дизайн
            </p>
            <p>
              Функционал любой сложности
            </p>
            <p>
              Полная адаптация под задачи
            </p>
            <p>
              Гарантийная поддержка и развитие
            </p>
          </div>
        </div>

        <div className="preview-box__exp">
          <img className="image-bgPhone" src={mainBgPhone} alt="#"/>

          <picture>
            <source srcSet={phonesWebp} type={'image/webp'}/>
            <img
                className="image-phones"
                src={phones}
                alt="#"
            />
          </picture>
        </div>
      </div>
      <ModalСonnection active={modal} setActive={setModal}/>
    </section>
  );
};

export default Preview;
