import React, { useEffect, useState } from "react";

import BaseInput from "../../ui/BaseInput/BaseInput";
import BaseButton from "../../ui/BaseButton/BaseButton";
import exit from "../../assets/icons/exitModal.svg";
import InputMask from "react-input-mask";

import "./ModalPresent.scss";
import { sendForm } from "../../api/sendForm";
import Loader from "../../ui/Loader/Loader";

const ModalСonnection = ({ active, setActive, dataCalc }) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const [nameDirty, setNameDirty] = useState(false);
    const [phoneDirty, setPhoneDirty] = useState(false);

    const [nameError, setNameError] = useState(
        "Строка с именем не может быть пустой"
    );
    const [emailError, setEmailError] = useState(
        "Строка с почтой не может быть пустой"
    );
    const [sourceError, setSourceError] = useState(
        "Напишите пару слов о проекте"
    );
    const [phoneError, setPhoneError] = useState(
        "Строка с номером телефона не может быть пустой"
    );

    const [checkbox, setCheckbox] = useState(false);

    const [disable, setDisable] = useState(true);
    const [statusSend, setStatusSend] = useState(false);
    const [textButton, setTextButton] = useState("Получить предложение");

    const [formValid, setFormValid] = useState(false);

    function nameHandler(e) {
        setName(e.target.value);
        if (e.target.value.length < 2) {
            setNameError("Имя должно быть длиннее 2 символов");
            if (!e.target.value) {
                setNameError("Строка с именем не может быть пустой");
            }
        } else {
            setNameError("");
        }
    }

    function phoneHandler(e) {
        setPhone(e.target.value);
        const re = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setPhoneError("Указан неверный номер телефона");
        } else {
            setPhoneError("");
        }
    }

    function checkboxHandler(e) {
        console.log(e.target.checked);
        if (e.target.checked) {
            setCheckbox(true);
        } else {
            setCheckbox(false);
        }
    }

    function blurHandler(e) {
        switch (e.target.name) {
            case "name":
                setNameDirty(true);
                break;

            case "phone":
                setPhoneDirty(true);
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        if (nameError || phoneError || !checkbox) {
            setFormValid(false);
            setDisable(true);
        } else {
            setFormValid(true);
            setDisable(false);
        }
    }, [nameError, phoneError, checkbox]);

    function submit(event) {
        event.preventDefault();

        if (formValid) {
            const data = new FormData(event.target);
            if (dataCalc) {
                dataCalc.forEach((value, key) => {
                    data.append(key, value);
                });
            }
            setTextButton("Отправка...");
            setStatusSend(true);
            sendForm(data).then((res) => {
                if (res.result) {
                    setStatusSend(false);
                    setTextButton("Заявка принята");
                    setDisable(true);
                    setTimeout(() => {
                        setActive(false);
                        setTextButton("Отправить заявку");
                        setName("");
                        setPhone("");
                    }, 3000);
                }
            });
        }
    }

    return (
        <div
            className={active ? "modalСonnection activeModal" : "modalСonnection"}
            onClick={() => setActive(false)}
        >
            <section
                className="modalСonnection-content modalСonnection-content--present"
                onClick={(e) => e.stopPropagation()}
            >
                <div className={'modalСonnection-content__wrapper'}>
                    <h1 className={"modalСonnection-content__title"}>
                        Получите подарок!
                    </h1>
                    <p className={"modalСonnection-content__description"}>
                        Оставьте заявку сейчас и получите дизайн-концепт главного экрана
                        приложения <span>в&nbsp; подарок</span> после КП
                    </p>
                    <form className="modalСonnection-content__body" onSubmit={submit}>
                        <div className="body-input">
                            <div className="body-input__head">
                                <p>Ваш номер</p>
                                <span>*</span>
                            </div>
                            <InputMask
                                mask="+7(999)999-99-99"
                                className={
                                    phoneDirty && phoneError
                                        ? "baseInput focus invalid"
                                        : "baseInput focus"
                                }
                                onChange={(e) => phoneHandler(e)}
                                onBlur={(e) => blurHandler(e)}
                                value={phone}
                                name={"phone"}
                                type={"tel"}
                                placeholder={"+7(___)___-__-__"}
                            ></InputMask>
                            <p className={phoneError ? "error-text" : ""}>
                                {phoneDirty && phoneError && phoneError}
                            </p>
                        </div>
                        <div className="body-input">
                            <div className="body-input__head">
                                <p>Ваше имя</p>
                                <span>*</span>
                            </div>
                            <BaseInput
                                onChange={(e) => nameHandler(e)}
                                onBlur={(e) => blurHandler(e)}
                                invalid={nameDirty && nameError}
                                value={name}
                                name={"name"}
                                type={"text"}
                                placeholder={"Имя"}
                            />
                            <p className={nameError ? "error-text" : ""}>
                                {nameDirty && nameError && nameError}
                            </p>
                        </div>
                        <div className="body-checkbox">
                            <input
                                type="checkbox"
                                onChange={(e) => checkboxHandler(e)}
                                value={checkbox}
                            />
                            <a href="https://webteamstorm.ru/docs/agreements/">
                                <h5>Соглашаюсь с правилами обрботки персональных данных </h5>
                            </a>
                        </div>
                        <BaseButton
                            styles={disable ? "button-modal disable" : "button-modal"}
                            disabled={!formValid && disable}
                            type="submit"
                        >
                            {textButton}
                            {statusSend ? <Loader/> : ""}
                        </BaseButton>
                        <img
                            className="image-exit"
                            src={exit}
                            alt="#"
                            onClick={() => setActive(false)}
                        />
                    </form>
                </div>
            </section>
        </div>
    );
};

export default ModalСonnection;
