import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  task: 0,
  design: 0,
  development: 0,
  time: 0,
};

export const calcSlice = createSlice({
  name: "calcSlice",
  initialState,
  reducers: {
    loadTask: (state, action) => {
      state.task += action.payload;
    },

    loadDisign: (state, action) => {
      state.design += action.payload;
    },

    loadDevelopment: (state, action) => {
      state.development += action.payload;
    },

    loadTime: (state, action) => {
      state.time += action.payload;
    },
  },
});

export const { loadTask, loadDisign, loadDevelopment, loadTime } =
  calcSlice.actions;

export const getTask = (state) => state.calc.task;

export const getDisign = (state) => state.calc.design;

export const getDevelopment = (state) => state.calc.development;

export const getTime = (state) => state.calc.time;

export default calcSlice.reducer;
