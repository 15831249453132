import React, {useEffect, useState} from "react";
import logoHeader from "../../assets/icons/logoHeader.svg";

import "./header.scss";

import BaseButton from "../../ui/BaseButton/BaseButton";
import Dropdown from "../Dropdown/Dropdown";
import ModalСonnection from "../ModalСonnection/ModalСonnection";
import HeaderMenu from "./HeaderMenu";

const Header = () => {
  const [modal, setModal] = useState(false);
  const [menuStatus, setMenuStatus] = useState(false);

  function toggleMenu() {
    if (menuStatus === false) {
      setMenuStatus(true);
      document.querySelector("body").classList.add("fixed");
    } else {
      setMenuStatus(false);
      document.querySelector("body").classList.remove("fixed");
    }
  }

  useEffect(() => {
    if (!modal && !menuStatus) {
      document.querySelector("body").classList.remove("fixed");
    }
  }, [modal, menuStatus]);

  return (
    <header className="header">
      <div className="header-box">
        <a href="#">
          <img className="img-logoHeader" src={logoHeader} alt="#" />
        </a>
        <nav className="header-box__nav">
          <Dropdown/>
          <a className="base-text" href="#solutions">
            Решения
          </a>
          <a className="base-text" href="#portfolio">
            Портфолио
          </a>
          <a className="base-text" href="#stages">
            Этапы
          </a>
          <a className="base-text" href="#calculator">
            Калькулятор
          </a>
          <a className="base-text" href="#whyWe">
            Преимущества
          </a>
          <a className="base-text" href="#contacts">
            Контакты
          </a>
        </nav>
        <BaseButton
            styles={"header-box__btn"}
            onClick={() => {
              setModal(true);
          }}
        >
          Связаться с нами
        </BaseButton>
        <ModalСonnection active={modal} setActive={setModal} />
        <button
          className={menuStatus ? "burger activeMenu" : "burger"}
          onClick={toggleMenu}
        ></button>
        <HeaderMenu
          status={menuStatus}
          setModal={setModal}
          statusMenu={setMenuStatus}
        />
      </div>
    </header>
  );
};

export default Header;
