import cn from 'classnames';
import cls from './Container.module.scss';

export const Container = (props) => {
    const { className, children } = props;

    return (
        <div className={cn(className, cls.container)}>
            {children}
        </div>
    );
}
