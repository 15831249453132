import React from "react";

import "./whyWe.scss";

const WhyWe = (props) => {
  return (
    <section className="whyWe">
      <div className="whyWe-box" id="whyWe">
        <h1 className="base-title">Почему мы?</h1>
        <div className="whyWe-box__item">
          <h2 className="whyWe-box__item-title">Организация процессов</h2>
          <p className="base-text">
            Мы стремимся обеспечить нашим клиентам максимально предсказуемый и
            прозрачный процесс разработки. Для этого мы применяем системный
            подход к управлению проектами и тщательно планируем и контролируем
            все этапы работы.
          </p>
        </div>

        <div className="whyWe-box__item">
          <h2 className="whyWe-box__item-title">Гибкая разработка</h2>
          <p className="whyWe-box__item-text">
            Наша разработка основана на гибкой методологии Scrum, которая
            позволяет нам разбить весь процесс на короткие итерации,
            длительностью от 5 до 10 дней. На каждой итерации мы определяем
            конкретные задачи и после их завершения предоставляем заказчику
            демонстрацию полученных результатов.
          </p>
        </div>

        <div className="whyWe-box__item">
          <h2 className="whyWe-box__item-title">Подробный анализ</h2>
          <p className="whyWe-box__item-text">
            Мы уделяем особое внимание глубокому анализу, чтобы предложить вам
            оптимальные технические решения для воплощения вашей идеи в жизнь.
            Мы тщательно прорабатываем бизнес-логику и функционал, чтобы они
            полностью удовлетворяли потребности и требования вашей целевой
            аудитории.
          </p>
        </div>

        <div className="whyWe-box__item">
          <h2 className="whyWe-box__item-title">Компетентный коллектив</h2>
          <p className="whyWe-box__item-text">
            Наша команда состоит из высококвалифицированных экспертов со всего
            мира. Мы не ограничиваемся границами одного города или страны,
            поэтому имеем возможность привлекать лучших специалистов и
            оставаться в курсе последних инноваций и решений. Это позволяет нам
            предлагать свежие и передовые подходы к разработке.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhyWe;
