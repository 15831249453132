import cls from "./Achievements.module.scss";
import { Container } from "../../components/Container/Container";

const Achievements = (props) => {
    return (
        <div className={cls.wrapper}>
            <Container>
                <div className={cls.inner}>
                    <div className={cls.card}>
                        <span className={cls.number}>1</span>
                        <p className={cls.text}>
                            Более <span>7 лет опыта</span> разработки мобильных приложений
                        </p>
                    </div>

                    <div className={cls.card}>
                        <span className={cls.number}>2</span>
                        <p className={cls.text}>
                            <span>100+</span> реализованных проектов
                        </p>
                    </div>

                    <div className={cls.card}>
                        <span className={cls.number}>3</span>
                        <p className={cls.text}>
                            Работаем с&nbsp;заказчиками из&nbsp;России, стран <span>СНГ, Европы и&nbsp;США</span>
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default Achievements;
