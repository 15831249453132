import React, { useEffect, useState } from "react";
import tgForm from "../../assets/icons/tgForm.svg";
import whatsappForm from "../../assets/icons/whatsappForm.svg";
import skype from "../../assets/icons/skypeForm.svg";
import bgImage from "../../assets/images/bgCulc.png";
import ArrowHead from "../../assets/icons/lineCalc.svg";
import InputMask from "react-input-mask";

import "./formContact.scss";
import BaseButton from "../../ui/BaseButton/BaseButton";
import BaseInput from "../../ui/BaseInput/BaseInput";
import Loader from "../../ui/Loader/Loader";
import { sendForm } from "../../api/sendForm";
import {useInViewLazy} from "../../hooks/useInView";

const FormContact = (props) => {
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const { ref, inView} = useInViewLazy();

  const [phoneDirty, setPhoneDirty] = useState(false);
  const [nameDirty, setNameDirty] = useState(false);

  const [phoneError, setPhoneError] = useState(
    "Строка с номером телефона не может быть пустой"
  );
  const [nameError, setNameError] = useState(
    "Строка с именем не может быть пустой"
  );

  const [checkbox, setCheckbox] = useState(false);

  const [disable, setDisable] = useState(true);
  const [statusSend, setStatusSend] = useState(false);
  const [textButton, setTextButton] = useState("Обратный звонок");

  const [formValid, setFormValid] = useState(false);

  function phoneHandler(e) {
    setPhone(e.target.value);
    const re = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setPhoneError("Указан неверный номер телефона");
    } else {
      setPhoneError("");
    }
  }

  function nameHandler(e) {
    setName(e.target.value);
    if (e.target.value.length < 2) {
      setNameError("Имя должно быть длиннее 2 символов");
      if (!e.target.value) {
        setNameError("Строка с именем не может быть пустой");
      }
    } else {
      setNameError("");
    }
  }

  function checkboxHandler(e) {
    console.log(e.target.checked);
    if (e.target.checked) {
      setCheckbox(true);
    } else {
      setCheckbox(false);
    }
  }

  function blurHandler(e) {
    switch (e.target.name) {
      case "name":
        setNameDirty(true);
        break;

      case "phone":
        setPhoneDirty(true);
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    if (nameError || phoneError || !checkbox) {
      setFormValid(false);
      setDisable(true);
    } else {
      setFormValid(true);
      setDisable(false);
    }
  }, [nameError, phoneError, checkbox]);

  function submit(event) {
    event.preventDefault();
    if (formValid) {
      const data = new FormData(event.target);
      setStatusSend(true);
      sendForm(data).then((res) => {
        if (res.result) {
          setStatusSend(false);
          setTextButton("Заявка принята");
          setDisable(true);
          setTimeout(() => {
            setTextButton("Обратный звонок");
            setName("");
            setPhone("");
          }, 5000);
        }
      });
    }
  }

  return (
    <section className="formContact" id={"contacts"} ref={ref}>
      <div className="formContact-box" id="formContact">
        <div className="formContact-box__head">
          <img className="image-arrowHead" src={ArrowHead} alt="#" />
          <h1 className="base-title">Ещё остались вопросы? </h1>
          <img className="image-arrowHead" src={ArrowHead} alt="#" />
        </div>
        <div className="formContact-box__form">
          <h2>Оставьте свой номер и мы с вами свяжемся в ближайшее время!</h2>
          <form className="formContact-box__form-body" onSubmit={submit}>
            <div className="body-input">
              <div className="body-input__head">
                <p>Ваш номер</p>
                <span>*</span>
              </div>
              <InputMask
                mask="+7(999)999-99-99"
                className={
                  phoneDirty && phoneError
                    ? "baseInput focus invalid"
                    : "baseInput focus"
                }
                onChange={(e) => phoneHandler(e)}
                onBlur={(e) => blurHandler(e)}
                value={phone}
                name={"phone"}
                type={"tel"}
                placeholder={"+7(___)___-__-__"}
              ></InputMask>
              <p className={phoneError ? "error-text" : ""}>
                {phoneDirty && phoneError && phoneError}
              </p>
            </div>
            <div className="body-input">
              <div className="body-input__head">
                <p>Ваше имя</p>
                <span>*</span>
              </div>
              <BaseInput
                onChange={(e) => nameHandler(e)}
                onBlur={(e) => blurHandler(e)}
                invalid={nameDirty && nameError}
                value={name}
                name={"name"}
                type={"text"}
                placeholder={"Имя"}
              />
              <p className={nameError ? "error-text" : ""}>
                {nameDirty && nameError && nameError}
              </p>
            </div>
            <div className="body-checkbox">
              <input
                type="checkbox"
                onChange={(e) => checkboxHandler(e)}
                value={checkbox}
              />
              <a href="https://webteamstorm.ru/docs/agreements/">
                <h5>Соглашаюсь с правилами обрботки персональных данных </h5>
              </a>
            </div>
            <BaseButton
              styles={disable ? "button-form disableForm" : "button-form"}
              disabled={!formValid && disable}
              type="submit"
            >
              {textButton}
            </BaseButton>
          </form>
        </div>
        <div className="formContact-box__network">
          <h3>Напишите нам : company@wsteam.com.ru</h3>
          <h3>Ответим на все вопросы:</h3>
          <div className="formContact-box__network-social">
            <a href="http://t.me/webteamstorm">
              <img className="image-network" src={tgForm} alt="#" />
            </a>
            <a href="https://wa.me/+79289566198">
              <img className="image-network" src={whatsappForm} alt="#" />
            </a>
            <a href="skype:live:severtaing">
              <img className="image-network" src={skype} alt="#" />
            </a>
          </div>
        </div>
        {inView && (
            <img className="image-bg" src={bgImage} alt="#"/>
        )}
      </div>
    </section>
  );
};

export default FormContact;
