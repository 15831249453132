import React, { useState } from "react";
import BaseButton from "../../../../ui/BaseButton/BaseButton";
import { useInViewLazy } from "../../../../hooks/useInView";

const SliderBottomItem = ({ item, setModal }) => {
  const [isPoint, setIsPoint] = useState(false);
  const { ref, inView} = useInViewLazy();

  function handleMouseEnter() {
    setIsPoint(true);
  }

  function handleMouseLeave() {
    setIsPoint(false);
  }

  return (
    <div className="portfolio-box__slider bottom-slide " ref={ref}>
      <div className="portfolio-box__slider-body bottom-slide-body">
        <h2 className="base-title">{item.title}</h2>
        <h3>Поставленные задачи:</h3>
        <ul>
          {item.list?.map((elem) => {
            return <li className="base-text">{elem}</li>;
          })}
        </ul>
        <div className="body-bottomSlide">
          <h3>Срок выполнения работы: </h3>
          <span>{item.days}</span>
        </div>
        <BaseButton
          styles={"button-sliderItem"}
          onClick={() => {
            setModal(true);
          }}
        >
          Оставить заявку
        </BaseButton>
      </div>
        <div
            className={
                isPoint ? "blackout-portfolio portfolio-link" : "portfolio-link"
            }
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {inView && (
                <picture>
                    <source srcSet={item.imageWebp} type={'image/webp'}/>
                    <img src={item.image} alt="#" className="image-slide"/>
                </picture>
            )}
            <a href={item.link} target={"_blank"}>
                <BaseButton
                    styles={
                        isPoint ? "button-active button-imageItem" : "button-imageItem"
                    }
                >
                    Подробнее
                </BaseButton>
            </a>
        </div>
    </div>
  );
};

export default SliderBottomItem;
