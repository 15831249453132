import React, { useEffect, useState } from "react";

import BaseButton from "../../../../ui/BaseButton/BaseButton";
import CheckboxSquare from "../../../../ui/CheckboxSquare/CheckboxSquare";
import { useDispatch, useSelector } from "react-redux";
import {
  getDevelopment,
  loadDevelopment,
  loadTime,
} from "../../store/calcSlice";

const Functional = ({ indexTab, setIndexTab }) => {
  const [disable, setDisable] = useState(true);
  const [checkedState, setCheckedState] = useState(new Array(12).fill(false));

  const [cost, setCost] = useState({
    development: 0,
    time: 0,
  });

  const dispatch = useDispatch();

  const test = useSelector(getDevelopment);

  console.log(test);

  const checked = checkedState
    .map((item, index) => {
      return {
        id: index,
        flag: item,
      };
    })
    .filter((item) => item.flag === true);

  function selectFunctional(id) {
    const resultCalc = checked.find((item) => item.id == id);

    switch (id) {
      case 0:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            development: cost.development + 0,
            time: cost.time + 0,
          });
        }

        break;

      case 1:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            development: cost.development + 20,
            time: cost.time + 4,
          });
        } else {
          setCost({
            ...cost,
            development: cost.development - 20,
            time: cost.time - 4,
          });
        }

        break;

      case 2:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            development: cost.development + 0,
            time: cost.time + 0,
          });
        }
        break;

      case 3:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            development: cost.development + 10,
            time: cost.time + 2,
          });
        } else {
          setCost({
            ...cost,
            development: cost.development - 10,
            time: cost.time - 2,
          });
        }
        break;

      case 4:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            development: cost.development + 10,
            time: cost.time + 2,
          });
        } else {
          setCost({
            ...cost,
            development: cost.development - 10,
            time: cost.time - 2,
          });
        }
        break;

      case 5:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            development: cost.development + 10,
            time: cost.time + 2,
          });
        } else {
          setCost({
            ...cost,
            development: cost.development - 10,
            time: cost.time - 2,
          });
        }
        break;

      case 6:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            development: cost.development + 20,
            time: cost.time + 4,
          });
        } else {
          setCost({
            ...cost,
            development: cost.development - 20,
            time: cost.time - 4,
          });
        }
        break;

      case 7:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            development: cost.development + 10,
            time: cost.time + 2,
          });
        } else {
          setCost({
            ...cost,
            development: cost.development - 10,
            time: cost.time - 2,
          });
        }
        break;

      case 8:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            development: cost.development + 30,
            time: cost.time + 6,
          });
        } else {
          setCost({
            ...cost,
            development: cost.development - 30,
            time: cost.time - 6,
          });
        }
        break;

      case 9:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            development: cost.development + 20,
            time: cost.time + 4,
          });
        } else {
          setCost({
            ...cost,
            development: cost.development - 20,
            time: cost.time - 4,
          });
        }
        break;

      case 10:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            development: cost.development + 40,
            time: cost.time + 10,
          });
        } else {
          setCost({
            ...cost,
            development: cost.development - 40,
            time: cost.time - 10,
          });
        }
        break;

      case 11:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            development: cost.development + 20,
            time: cost.time + 4,
          });
        } else {
          setCost({
            ...cost,
            development: cost.development - 20,
            time: cost.time - 4,
          });
        }
        break;
    }
  }

  function addState() {
    dispatch(loadDevelopment(cost.development));
    dispatch(loadTime(cost.time));
  }

  function handleChange(id) {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === id ? !item : item
    );
    setCheckedState(updatedCheckedState);
  }

  useEffect(() => {
    const istest = checkedState.every((item) => {
      return item === false;
    });

    if (istest == false) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [checkedState]);

  return (
    <section
      className={
        indexTab === 2 ? "active-calcTab calc-box__body" : "calc-box__body"
      }
    >
      <div className="calc-box__body-head">
        <h2>Шаг 2</h2>
        <h3>Выберите необходимый функционал</h3>
      </div>
      <div className="calc-box__body-containerFunc">
        <div className="container-block">
          <div className="container-checkbox">
            <CheckboxSquare
              text={"Профиль"}
              id={"7"}
              name={"prifile"}
              value={"Профиль"}
              onChange={() => {
                handleChange(0);
              }}
              onClick={() => selectFunctional(0)}
            />
            <CheckboxSquare
              text={"Корзина"}
              id={"8"}
              name={"pasket"}
              value={"Корзина"}
              onChange={() => {
                handleChange(1);
              }}
              onClick={() => selectFunctional(1)}
            />
            <CheckboxSquare
              text={"Каталог"}
              id={"9"}
              name={"catalog"}
              value={"Каталог"}
              onChange={() => {
                handleChange(2);
              }}
              onClick={() => selectFunctional(2)}
            />
          </div>
          <div className="container-checkbox">
            <CheckboxSquare
              text={"История заказов"}
              id={"10"}
              name={"orderHistory"}
              value={"История заказов"}
              onChange={() => {
                handleChange(3);
              }}
              onClick={() => selectFunctional(3)}
            />
            <CheckboxSquare
              text={"Система лояльности"}
              id={"11"}
              name={"loyaltySystem"}
              value={"Система лояльности"}
              onChange={() => {
                handleChange(4);
              }}
              onClick={() => selectFunctional(4)}
            />
            <CheckboxSquare
              text={"Регистрация через номер"}
              id={"12"}
              name={"registrByNum"}
              value={"Регистрация через номер"}
              onChange={() => {
                handleChange(5);
              }}
              onClick={() => selectFunctional(5)}
            />
          </div>
        </div>
        <div className="container-block">
          <div className="container-checkbox">
            <CheckboxSquare
              text={"Поиск"}
              id={"13"}
              name={"search"}
              value={"Поиск"}
              onChange={() => {
                handleChange(6);
              }}
              onClick={() => selectFunctional(6)}
            />
            <CheckboxSquare
              text={"Отзывы"}
              id={"14"}
              name={"reviews"}
              value={"Отзывы"}
              onChange={() => {
                handleChange(7);
              }}
              onClick={() => selectFunctional(7)}
            />
            <CheckboxSquare
              text={"Фильтры"}
              id={"15"}
              name={"filters"}
              value={"Фильтры"}
              onChange={() => {
                handleChange(8);
              }}
              onClick={() => selectFunctional(8)}
            />
          </div>
          <div className="container-checkbox">
            <CheckboxSquare
              text={"Push - уведомления"}
              id={"16"}
              name={"push"}
              value={"Push - уведомления"}
              onChange={() => {
                handleChange(9);
              }}
              onClick={() => selectFunctional(9)}
            />
            <CheckboxSquare
              text={"Карты / интеграция ПО"}
              id={"17"}
              name={"cards"}
              value={"Карты / интеграция ПО"}
              onChange={() => {
                handleChange(10);
              }}
              onClick={() => selectFunctional(10)}
            />
            <CheckboxSquare
              text={"Оформление заказа"}
              id={"18"}
              name={"makeOrder"}
              value={"Оформление заказа"}
              onChange={() => {
                handleChange(11);
              }}
              onClick={() => selectFunctional(11)}
            />
          </div>
        </div>
      </div>
      <div className="calc-box__body-bottom">
        <BaseButton
          type="button"
          styles={disable ? "bottom-calc__btn disableCalc" : "bottom-calc__btn"}
          disabled={disable}
          onClick={() => {
            setIndexTab(3);
            addState();
          }}
        >
          Далее
        </BaseButton>
        <span>{indexTab}/4</span>
      </div>
    </section>
  );
};

export default Functional;
