import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Questions from "../../modules/Questions/components/Quetions/Questions";
import WhyWe from "../../modules/WhyWe/WhyWe";
import DevProcess from "../../modules/DevProcess/DevProcess";
import Calculator from "../../modules/Calculator/components/Calculator/Calculator";
import FormContact from "../../modules/FormContact/FormContact";
import Portfolio from "../../modules/Portfolio/components/Portfolio/Portfolio";
import Preview from "../../modules/Preview/Preview";
import Achievements from "../../modules/Achievements/Achievements";
import Cases from '../../modules/Cases/components/Cases/Cases';
import WorkingPlatform from "../../modules/WorkingPlatform/WorkingPlatform";

const Home = (props) => {
  return (
    <>
      <Header />
      <main>
        <Preview />
        <Achievements />
        <Cases />
        <WorkingPlatform />
        <Portfolio />
        <DevProcess />
        <Calculator />
        <WhyWe />
        <Questions />
        <FormContact />
      </main>
      <Footer />
    </>
  );
};

export default Home;
