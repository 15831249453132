import React, { useEffect, useState } from "react";

import BaseButton from "../../../../ui/BaseButton/BaseButton";
import CheckboxSquare from "../../../../ui/CheckboxSquare/CheckboxSquare";
import { useDispatch } from "react-redux";
import { loadDevelopment, loadDisign, loadTime } from "../../store/calcSlice";

const Platform = ({ indexTab, setIndexTab }) => {
  const [disable, setDisable] = useState(true);
  const [checkedState, setCheckedState] = useState(new Array(12).fill(false));

  const [cost, setCost] = useState({
    design: 0,
    development: 0,
    time: 0,
  });

  const dispatch = useDispatch();

  const checked = checkedState
    .map((item, index) => {
      return {
        id: index,
        flag: item,
      };
    })
    .filter((item) => item.flag === true);

  function selectPlatform(id) {
    const resultCalc = checked.find((item) => item.id == id);

    switch (id) {
      case 0:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            disign: cost.design + 30,
            development: cost.development + 50,
            time: cost.time + 10,
          });
        } else {
          setCost({
            ...cost,
            disign: cost.design - 30,
            development: cost.development - 50,
            time: cost.time - 10,
          });
        }
        break;

      case 1:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            disign: cost.design + 0,
            development: cost.development + 0,
            time: cost.time + 0,
          });
        }
        break;

      case 2:
        if (resultCalc == undefined) {
          setCost({
            ...cost,
            disign: cost.design + 20,
            development: cost.development + 40,
            time: cost.time + 5,
          });
        } else {
          setCost({
            ...cost,
            disign: cost.design - 20,
            development: cost.development - 40,
            time: cost.time - 5,
          });
        }
        break;
    }
  }

  function addState() {
    dispatch(loadDisign(cost.design));
    dispatch(loadDevelopment(cost.development));
    dispatch(loadTime(cost.time));
  }

  function handleChange(id) {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === id ? !item : item
    );
    setCheckedState(updatedCheckedState);
  }

  useEffect(() => {
    const istest = checkedState.every((item) => {
      return item === false;
    });

    if (istest == false) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [checkedState]);

  return (
    <section
      className={
        indexTab === 3 ? "active-calcTab calc-box__body" : "calc-box__body"
      }
    >
      <div className="calc-box__body-head">
        <h2>Шаг 3</h2>
        <h3>Для каких платформ разрабатывается приложение?</h3>
      </div>
      <div className="calc-box__body-container">
        <div className="container-checkbox">
          <CheckboxSquare
            text={"IOS"}
            id={"19"}
            name={"ios"}
            value={"IOS"}
            onChange={() => {
              handleChange(0);
            }}
            onClick={() => selectPlatform(0)}
          />
          <CheckboxSquare
            text={"Android"}
            id={"20"}
            name={"android"}
            value={"Android"}
            onChange={() => {
              handleChange(1);
            }}
            onClick={() => selectPlatform(1)}
          />
          <CheckboxSquare
            text={"App galery"}
            id={"21"}
            name={"appGalery"}
            value={"App galery"}
            onChange={() => {
              handleChange(2);
            }}
            onClick={() => selectPlatform(2)}
          />
        </div>
      </div>
      <div className="calc-box__body-bottom">
        <BaseButton
          type="button"
          styles={disable ? "bottom-calc__btn disableCalc" : "bottom-calc__btn"}
          disabled={disable}
          onClick={() => {
            setIndexTab(4);
            addState();
          }}
        >
          Далее
        </BaseButton>
        <span>{indexTab}/4</span>
      </div>
    </section>
  );
};

export default Platform;
