import React, { useState } from "react";

import CheckboxCircle from "../../../../ui/CheckboxCircle/CheckboxCircle";
import BaseButton from "../../../../ui/BaseButton/BaseButton";
import { useDispatch } from "react-redux";
import {
  loadDevelopment,
  loadDisign,
  loadTask,
  loadTime,
} from "../../store/calcSlice";

const AppDerection = ({ indexTab, setIndexTab }) => {
  const [disable, setDisable] = useState(true);
  const [check, setCheck] = useState(false);

  const [cost, setCost] = useState({
    task: 0,
    design: 0,
    development: 0,
    time: 0,
  });

  const dispatch = useDispatch();

  function selectService(id) {
    if (id === 1 || id === 3 || id === 5) {
      setCost({ ...cost, task: 30, design: 60, development: 100, time: 40 });
    } else {
      setCost({ ...cost, task: 20, design: 50, development: 80, time: 30 });
    }
  }

  function addState() {
    dispatch(loadTask(cost.task));
    dispatch(loadDisign(cost.design));
    dispatch(loadDevelopment(cost.development));
    dispatch(loadTime(cost.time));
  }

  function activeButton() {
    setCheck(true);
    setDisable(false);
  }

  return (
    <section
      className={
        indexTab === 1 ? "active-calcTab calc-box__body" : "calc-box__body"
      }
    >
      <div className="calc-box__body-head">
        <h2>Шаг 1</h2>
        <h3>Выберетие направление приложения </h3>
      </div>
      <div className="calc-box__body-container">
        <div className="container-checkbox">
          <CheckboxCircle
            text={"Социальная сеть"}
            id={"1"}
            name={"appDirection"}
            value={"Социальная сеть"}
            onChange={activeButton}
            onClick={() => selectService(1)}
          />
          <CheckboxCircle
            text={"Приложение для досуга"}
            id={"2"}
            name={"appDirection"}
            value={"Приложение для досуга"}
            onChange={activeButton}
            onClick={() => selectService(2)}
          />
          <CheckboxCircle
            text={"Приложение для знакомств"}
            id={"3"}
            name={"appDirection"}
            value={"Приложение для знакомств"}
            onChange={activeButton}
            onClick={() => selectService(3)}
          />
        </div>
        <div className="container-checkbox">
          <CheckboxCircle
            text={"Приложение для доставки"}
            id={"4"}
            name={"appDirection"}
            value={"Приложение для доставки"}
            onChange={activeButton}
            onClick={() => selectService(4)}
          />
          <CheckboxCircle
            text={"Приложения для Маркетплейсов"}
            id={"5"}
            name={"appDirection"}
            value={"Приложения для Маркетплейсов"}
            onChange={activeButton}
            onClick={() => selectService(5)}
          />
          <CheckboxCircle
            text={"Другое"}
            id={"6"}
            name={"appDirection"}
            value={"Другое"}
            onChange={activeButton}
            onClick={() => selectService(6)}
          />
        </div>
      </div>
      <div className="calc-box__body-bottom">
        <BaseButton
          type="button"
          styles={!check ? "bottom-calc__btn disableCalc" : "bottom-calc__btn"}
          disabled={disable}
          onClick={() => {
            setIndexTab(2);
            addState();
          }}
        >
          далее
        </BaseButton>
        <span>{indexTab}/4</span>
      </div>
    </section>
  );
};

export default AppDerection;
