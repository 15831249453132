import projectSlide1 from "../../assets/images/projectSlide1.png";
import projectSlide1Webp from "../../assets/images/projectSlide1.webp";
import projectSlide2 from "../../assets/images/projectSlide2.png";
import projectSlide2Webp from "../../assets/images/projectSlide2.webp";
import projectSlide3 from "../../assets/images/projectSlide3.png";
import projectSlide3Webp from "../../assets/images/projectSlide3.webp";
import projectSlide4 from "../../assets/images/projectSlide4.png";
import projectSlide4Webp from "../../assets/images/projectSlide4.webp";

export const sliderTopMock = [
  {
    title: "SportApp - Поиск спортивных мероприятий",
    image: projectSlide1,
    imageWebp: projectSlide1Webp,
    list: [
      "Шаблонный дизайн",
      "Web-View",
      "Работа с картами и геолокацией",
      "Мультирегиональность",
      "Внутренний мессенджер",
    ],
    days: "30 дней",
  },

  {
    title: "DiscountCenter - Интернет-магазин одежды, обуви и аксессуаров",
    image: projectSlide2,
    imageWebp: projectSlide2Webp,
    list: [
      "Создание оригинального дизайна",
      "Ознакомление пользователей с номенклатурой магазина",
      "Оформление заказа",
      "Вывод акций и актуальных программ",
      "Разработка под Android и iOS",
    ],
    days: "40 дней",
  },
];

export const sliderBottomMock = [
  {
    title: "- Рабочее место менеджера магазина",
    link: "HLC.COM.CY ",
    image: projectSlide3,
    imageWebp: projectSlide3Webp,
    list: [
      "Корпоративный дизайн",
      "Поддержка сокетов для динамического общения",
      "Сбор и аналитика данных",
      "Менеджмент доступов",
      "Двухфакторная авторизация",
    ],
    days: "50 дней",
  },

  {
    title: "Artfit - Реализация тренингов и тренажеров",
    image: projectSlide4,
    imageWebp: projectSlide4Webp,
    list: [
      "Подбор персонального плана тренировок",
      "Продажа внутренней атрибутики",
      "Продуманная система хранения внутренних данных",
      "Онлайн консультант",
      "Длительная поддержка",
    ],
    days: "50 дней",
  },
];

export const mainMockProject = [
  {
    title: "SportApp - Поиск спортивных мероприятий",
    image: projectSlide1,
    imageWebp: projectSlide1Webp,
    link: "https://webteamstorm.ru/projects/prilojenie_podbor_sportivnuh_meropriyatiyi_32/",
    list: [
      "Шаблонный дизайн",
      "Web-View",
      "Работа с картами и геолокацией",
      "Мультирегиональность",
      "Внутренний мессенджер",
    ],
    days: "30 дней",
  },

  {
    title: "HLC.COM.CY - Рабочее место менеджера магазина",
    image: projectSlide3,
    imageWebp: projectSlide3Webp,
    link: "https://webteamstorm.ru/projects/prilojenie_hl_dlya_ypravleniya_menedjerami_33/",
    list: [
      "Корпоративный дизайн",
      "Поддержка сокетов для динамического общения",
      "Сбор и аналитика данных",
      "Менеджмент доступов",
      "Двухфакторная авторизация",
    ],
    days: "50 дней",
  },

  {
    title: "DiscountCenter - Интернет-магазин одежды, обуви и аксессуаров",
    image: projectSlide2,
    imageWebp: projectSlide2Webp,
    link: "https://webteamstorm.ru/projects/prilojenie_magazin_vesheyi_34/",
    list: [
      "Создание оригинального дизайна",
      "Ознакомление пользователей с номенклатурой магазина",
      "Оформление заказа",
      "Вывод акций и актуальных программ",
      "Разработка под Android и iOS",
    ],
    days: "40 дней",
  },

  {
    title: "Artfit - Реализация тренингов и тренажеров",
    image: projectSlide4,
    imageWebp: projectSlide4Webp,
    link: "https://webteamstorm.ru/projects/mobilnoe_prilojenie_artfit_48/",
    list: [
      "Подбор персонального плана тренировок",
      "Продажа внутренней атрибутики",
      "Продуманная система хранения внутренних данных",
      "Онлайн консультант",
      "Длительная поддержка",
    ],
    days: "50 дней",
  },
];
