import cn from 'classnames';
import cls from './WorkingPlatform.module.scss';
import platforms from '../../assets/images/platforms.png';
import platformsWebp from '../../assets/images/platforms.webp';
import React from "react";
import { Container } from "../../components/Container/Container";
import { useInViewLazy } from "../../hooks/useInView";

const WorkingPlatform = (props) => {
    const {className} = props;
    const { ref, inView} = useInViewLazy();

    return (
        <div className={cls.wrapper} ref={ref}>
            <Container className={cn(className)}>
                <div className={cls.header}>
                    <h2 className={cls.title}>Работаем с платформами</h2>
                </div>

                <div className={cls.inner}>
                    <div className={cls.cardWrapper}>
                        <div className={cls.card}>
                            <span className={cls.tag}>iOS</span>
                            <p className={cls.text}>Проходим модерацию Apple без манипуляций. Создаем полноценные приложения
                                на React-Native,
                                которые запускаются на всех актуальных моделях iPhone и iPad.</p>
                        </div>

                        <div className={cls.card}>
                            <span className={cls.tag}>Android</span>
                            <p className={cls.text}>Нативные программы на RN и Cordova, которые адаптивно
                                отображаются на всех актуальных смартфонах Android.
                                Выполняем всю необходимую настройку для Google Play.</p>
                        </div>
                    </div>
                    <div className={cls.imageWrapper}>
                        {inView && (
                            <picture>
                                <source srcSet={platformsWebp} type={'image/webp'}/>
                                <img src={platforms} alt="#" className={cls.image}/>
                            </picture>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default WorkingPlatform;
