import React, { useState } from "react";
import CheckboxCircle from "../../../../ui/CheckboxCircle/CheckboxCircle";
import BaseButton from "../../../../ui/BaseButton/BaseButton";
import { useDispatch } from "react-redux";
import { loadDisign } from "../../store/calcSlice";

const Design = ({ indexTab, setIndexTab }) => {
  const [disable, setDisable] = useState(true);
  const [check, setCheck] = useState(false);

  const [cost, setCost] = useState({
    design: 0,
  });

  const dispatch = useDispatch();

  function selectDisign(id) {
    if (id === 22) {
      setCost({ ...cost, design: 20 });
    } else {
      setCost({ ...cost, design: 0 });
    }
  }

  function addState() {
    dispatch(loadDisign(cost.design));
  }

  function activeButton() {
    setCheck(true);
    setDisable(false);
  }

  return (
    <section
      className={
        indexTab === 4 ? "active-calcTab calc-box__body" : "calc-box__body"
      }
    >
      <div className="calc-box__body-head">
        <h2>Шаг 4</h2>
        <h3>Дизайн</h3>
      </div>
      <div className="calc-box__body-container">
        <div className="container-checkbox">
          <CheckboxCircle
            text={"Нужен дизайн с 0"}
            id={"22"}
            name={"design"}
            value={"Нужен дизайн с 0"}
            onChange={activeButton}
            onClick={() => selectDisign(22)}
          />
          <CheckboxCircle
            text={"Есть идеи о дизайне"}
            id={"23"}
            name={"design"}
            value={"Есть идеи о дизайне"}
            onChange={activeButton}
            onClick={() => selectDisign(23)}
          />
          <CheckboxCircle
            text={"Есть брендбук компании"}
            id={"24"}
            name={"design"}
            value={"Есть брендбук компании"}
            onChange={activeButton}
            onClick={() => selectDisign(24)}
          />
        </div>
        <div className="container-checkbox">
          <CheckboxCircle
            text={"Есть фирменный сайт "}
            id={"25"}
            name={"design"}
            value={"Есть фирменный сайт "}
            onChange={activeButton}
            onClick={() => selectDisign(25)}
          />
          <CheckboxCircle
            text={"Есть единая стилистика компании"}
            id={"26"}
            name={"design"}
            value={"Есть единая стилистика компании"}
            onChange={activeButton}
            onClick={() => selectDisign(26)}
          />
          <CheckboxCircle
            text={"Другое"}
            id={"27"}
            name={"design"}
            value={"Другое"}
            onChange={activeButton}
            onClick={() => selectDisign(27)}
          />
        </div>
      </div>
      <div className="calc-box__body-bottom">
        <BaseButton
          type="button"
          styles={!check ? "bottom-calc__btn disableCalc" : "bottom-calc__btn"}
          disabled={disable}
          onClick={() => {
            setIndexTab(5);
            addState();
          }}
        >
          Узнать стоимость
        </BaseButton>
        <span>{indexTab}/4</span>
      </div>
    </section>
  );
};

export default Design;
