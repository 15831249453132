
import { useRef, useEffect, useState } from "react";
import { CasesCard } from "../CasesCard/CasesCard";
import { Container } from "../../../../components/Container/Container";
import cn from 'classnames';
import cls from './Cases.module.scss';
import { casesMock } from "../../casesMock";
import arrowRight from '../../../../assets/images/cases/arrow-right.svg'
import arrowLeft from '../../../../assets/images/cases/arrow-left.svg'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { useMediaQuery } from 'react-responsive';

const Cases = (props) => {
    const { className } = props;
    const swiperRef = useRef();
    const [isShowPrev, setIsShowPrev] = useState(false);
    const [isShowNext, setIsShowNext] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

    console.log('isMobile', isMobile)

    useEffect(() => {
        setIsShowNext(false);
        setIsShowPrev(true);
    }, [isMobile]);

    const onChangeSlider = (swiper) => {
        if (swiper.slides.length - 1 === swiper.activeIndex) {
            setIsShowNext(true);
        } else {
            setIsShowNext(false);
        }


        if (!swiper.activeIndex) {
            setIsShowPrev(true);
        } else {
            setIsShowPrev(false);
        }
    };

    return (
        <Container>
            <div className={cls.header}>
                <h2 className={cn(cls.title)}>Что мы делаем ?</h2>

                {isMobile && (
                    <div className={cls.arrows}>
                        <button
                            type={"button"}
                            onClick={() => swiperRef.current?.slidePrev()}
                            className={cn(
                                cls.sliderController,
                                cls.sliderControllerPrev,
                                {
                                    [cls.isShowPrev]: isShowPrev,
                                },
                            )}
                        >
                            <img src={arrowLeft} alt={'Кнопка назад'}/>
                        </button>
                        <button
                            type={"button"}
                            onClick={() => swiperRef.current?.slideNext()}
                            className={cn(
                                cls.sliderController,
                                cls.sliderControllerNext,
                                {
                                    [cls.isShowNext]: isShowNext,
                                },
                            )}
                        >
                            <img src={arrowRight} alt={'Кнопка вперёд'}/>
                        </button>
                    </div>
                )}
            </div>

            {!isMobile && (
                <div className={cls.box}>
                    {casesMock.map(item => (
                        <CasesCard
                            key={item.id}
                            title={item.title}
                            image={item.image}
                            imageWebp={item.imageWebp}
                        />
                    ))}
                </div>
            )}

            {isMobile && (
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={50}
                    slidesPerView={1}
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    // loop={isMobile ? true : false}
                    onSwiper={() => console.log('onSWIPER')}
                    onSlideChange={(swiper) => onChangeSlider(swiper)}
                >
                    <SwiperSlide className={cls.slide}>
                        <div className={cls.slideWrapper}>
                            {casesMock.map((item, i) => (
                                <>
                                    {i <= 4 && (
                                        <CasesCard
                                            key={item.id}
                                            title={item.title}
                                            image={item.image}
                                            imageWebp={item.imageWebp}
                                        />
                                    )}
                                </>
                            ))}
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={cls.slide}>
                        <div className={cls.slideWrapper}>
                            {casesMock.map((item, i) => (
                                <>
                                    {i > 4 && (
                                        <CasesCard
                                            key={item.id}
                                            title={item.title}
                                            image={item.image}
                                            imageWebp={item.imageWebp}
                                        />
                                    )}
                                </>
                            ))}
                        </div>
                    </SwiperSlide>
                </Swiper>
            )}

        </Container>
    );
}

export default Cases;
