import React from "react";

import bgQuestions from "../../../../assets/images/questionBackground.png";
import bgQuestionsWebp from "../../../../assets/images/questionBackground.webp";
import { questionsMock } from "../../questionsMock";
import QuestionsItems from "../QuestionsItems/QuestionsItems";

import "./questions.scss";
import devBg4Webp from "../../../../assets/images/devBackdround4.webp";
import devBg4 from "../../../../assets/images/devBackdround4.png";
import { useInViewLazy } from "../../../../hooks/useInView";

const Questions = (props) => {
  const { ref, inView} = useInViewLazy();

  return (
    <section className="questions" ref={ref}>
      <div className="questions-box">
        <h1 className="base-title">Вопрос — ответ</h1>
        <div className="questions-box__body">
          <div className="questions-box__body-items">
            {questionsMock?.map((item) => {
              return <QuestionsItems item={item}/>;
            })}
          </div>

          {inView && (
              <picture>
                <source srcSet={bgQuestionsWebp} type={'image/webp'}/>
                <img className="image-questions"
                     src={bgQuestions}
                     alt="#"/>
              </picture>
          )}
        </div>
      </div>
    </section>
  );
};

export default Questions;
