import React, { useState } from "react";

import BaseButton from "../../../../ui/BaseButton/BaseButton";
import arrowPortfolio from "../../../../assets/icons/arrowPortfolio.svg";

import "./portfolio.scss";
import { mainMockProject } from "../../slidersMock";
import SliderTopItem from "../SliderItems/SliderTopItem";
import SliderBottomItem from "../SliderItems/SliderBottomItem";
import ModalСonnection from "../../../../components/ModalСonnection/ModalСonnection";
import {Container} from "../../../../components/Container/Container";

const Portfolio = (props) => {
  const [modal, setModal] = useState(false);
  return (
    <section className="portfolio">
      <div className="portfolio-box" id="portfolio">
        <h1 className="base-title">Наши проекты</h1>

        {mainMockProject?.map((item, index) => {
          if (index == 0 || index == 2) {
            return <SliderTopItem item={item} setModal={setModal} />;
          } else {
            return <SliderBottomItem item={item} setModal={setModal} />;
          }
        })}

        <div className="portfolio-box__bottom">
          <img
            className={"image-portfolioArrow"}
            src={arrowPortfolio}
            alt="#"
          />
          <a href="https://webteamstorm.ru/projects/mobile-applications/">
            <BaseButton styles={"button-portfolio"}>
              Смотреть все проекты
            </BaseButton>
          </a>

          <img
            className={"image-portfolioArrow"}
            src={arrowPortfolio}
            alt="#"
          />
        </div>
      </div>
      <ModalСonnection active={modal} setActive={setModal} />
    </section>
  );
};

export default Portfolio;
