import React, { useState } from "react";

import dropdownArrow from "../../assets/icons/dropdownArrow.svg";

import "./dropdown.scss";

const Dropdown = (props) => {
  const [open, setOpen] = useState(false);

  function toggle() {
    if (open == true) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }
  return (
    <div
      className={open ? "dropdown activeDd" : "dropdown"}
      onClick={() => {
        toggle();
      }}
    >
      <div className="dropdown-head">
        <p className="base-text">Услуги</p>
        <img
          className={open ? "activeArrow" : ""}
          src={dropdownArrow}
          alt="#"
        />
      </div>
      <div
        className={
          open ? "dropdown-content activeDd-content " : "dropdown-content"
        }
      >
        <a href="https://lp.webteamstorm.ru/">
          <p className="base-text">Разработка лендинга</p>
        </a>
        <a href="https://webteamstorm.ru/services/shop/">
          <p className="base-text">Разработка интернет-магазина</p>
        </a>
      </div>
    </div>
  );
};

export default Dropdown;
