export const questionsMock = [
  {
    title: " Сколько вариантов дизайна вы делаете в рамках договора?",
    text: "В зависимости от выбранного Вами тарифа, мы предоставляем на выбор до 3-х отличных друг от друга концептов. Подбираем референсы или опираемся на корпоративный стиль, если он есть",
  },

  {
    title: " Какую вы предоставляете гарантию?",
    text: "Мы даем гарантию в рамках договора сроком до 12 месяцев. В нее входит устранение технических ошибок, которые могли быть допущены в ходе разработки. Срок исправления до 7 рабочих дней.",
  },

  {
    title: "Какая у вас система оплаты и отчетность?",
    text: " Поэтапная предоплата, безнал, ИП. Регулярная демонстрация работы на наших серверах. Закрытие этапа по актам и утверждению с двух сторон.",
  },

  {
    title: "Будет ли выделен отдельный менеджер под проект?",
    text: "Да, за каждым проектом закрепляется персональный менеджер для коммуникации и запроса данных.",
  },

  {
    title: " Возможна ли дальнейшая разработка и поддержка приложения?",
    text: "Да, мы предлагаем целый комплекс для готовых продуктов. Предлагаются персональные планы по поддержке и развитию приложений.",
  },
];
