import React from "react";

import "./checkboxSquare.css";

const CheckboxSquare = ({ styles, id, name, value, text, ...props }) => {
  return (
    <div className="checkboxSquare-box">
      <input
        type="checkbox"
        className="checkboxSquare "
        id={id}
        name={name}
        value={value}
        {...props}
      />
      <label className="checkboxCircle-label" htmlFor={id}>
        {text}
      </label>
    </div>
  );
};

export default CheckboxSquare;
