import React, { useEffect, useState } from "react";

import BaseInput from "../../ui/BaseInput/BaseInput";
import BaseButton from "../../ui/BaseButton/BaseButton";
import exit from "../../assets/icons/exitModal.svg";
import InputMask from "react-input-mask";

import "./modalСonnection.scss";
import { sendForm } from "../../api/sendForm";
import Loader from "../../ui/Loader/Loader";

const ModalСonnection = ({ active, setActive, dataCalc }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [source, setSource] = useState("");
  const [phone, setPhone] = useState("");

  const [nameDirty, setNameDirty] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [sourceDirty, setSourceDirty] = useState(false);
  const [phoneDirty, setPhoneDirty] = useState(false);

  const [nameError, setNameError] = useState(
    "Строка с именем не может быть пустой"
  );
  const [emailError, setEmailError] = useState(
    "Строка с почтой не может быть пустой"
  );
  const [sourceError, setSourceError] = useState(
    "Напишите пару слов о проекте"
  );
  const [phoneError, setPhoneError] = useState(
    "Строка с номером телефона не может быть пустой"
  );

  const [checkbox, setCheckbox] = useState(false);

  const [disable, setDisable] = useState(true);
  const [statusSend, setStatusSend] = useState(false);
  const [textButton, setTextButton] = useState("Оставить заявку");

  const [formValid, setFormValid] = useState(false);

  function nameHandler(e) {
    setName(e.target.value);
    if (e.target.value.length < 2) {
      setNameError("Имя должно быть длиннее 2 символов");
      if (!e.target.value) {
        setNameError("Строка с именем не может быть пустой");
      }
    } else {
      setNameError("");
    }
  }

  function emailHandler(e) {
    setEmail(e.target.value);
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setEmailError("Указан неверный адрес почты");
    } else {
      setEmailError("");
    }
  }

  function phoneHandler(e) {
    setPhone(e.target.value);
    const re = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
    if (!re.test(String(e.target.value).toLowerCase())) {
      setPhoneError("Указан неверный номер телефона");
    } else {
      setPhoneError("");
    }
  }

  function sourceHandler(e) {
    setSource(e.target.value);
    if (e.target.value.length < 5) {
      setSourceError("Напишите пару слов о проекте");
    } else {
      setSourceError("");
    }
  }

  function checkboxHandler(e) {
    console.log(e.target.checked);
    if (e.target.checked) {
      setCheckbox(true);
    } else {
      setCheckbox(false);
    }
  }

  function blurHandler(e) {
    switch (e.target.name) {
      case "name":
        setNameDirty(true);
        break;

      case "email":
        setEmailDirty(true);
        break;

      case "source":
        setSourceDirty(true);
        break;

      case "phone":
        setPhoneDirty(true);
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    if (nameError || emailError || sourceError || phoneError || !checkbox) {
      setFormValid(false);
      setDisable(true);
    } else {
      setFormValid(true);
      setDisable(false);
    }
  }, [nameError, emailError, sourceError, phoneError, checkbox]);

  function submit(event) {
    event.preventDefault();
    if (formValid) {
      const data = new FormData(event.target);
      if (dataCalc) {
        dataCalc.forEach((value, key) => {
          data.append(key, value);
        });
      }
      setTextButton("Отправка...");
      setStatusSend(true);
      sendForm(data).then((res) => {
        if (res.result) {
          setStatusSend(false);
          setTextButton("Заявка принята");
          setDisable(true);
          setTimeout(() => {
            setActive(false);
            setTextButton("Отправить заявку");
            setName("");
            setEmail("");
            setSource("");
            setPhone("");
          }, 3000);
        }
      });
    }
  }

  return (
    <div
      className={active ? "modalСonnection activeModal" : "modalСonnection"}
      onClick={() => setActive(false)}
    >
      <section
        className="modalСonnection-content"
        onClick={(e) => e.stopPropagation()}
      >
        <h1 className={'modalСonnection-content__title'}>Оставьте заявку на бесплатную консультацию прямо сейчас!</h1>
        <form className="modalСonnection-content__body" onSubmit={submit}>
          <div className="body-input">
            <div className="body-input__head">
              <p>Ваш номер</p>
              <span>*</span>
            </div>
            <InputMask
              mask="+7(999)999-99-99"
              className={
                phoneDirty && phoneError
                  ? "baseInput focus invalid"
                  : "baseInput focus"
              }
              onChange={(e) => phoneHandler(e)}
              onBlur={(e) => blurHandler(e)}
              value={phone}
              name={"phone"}
              type={"tel"}
              placeholder={"+7(___)___-__-__"}
            ></InputMask>
            <p className={phoneError ? "error-text" : ""}>
              {phoneDirty && phoneError && phoneError}
            </p>
          </div>
          <div className="body-input">
            <div className="body-input__head">
              <p>Ваше имя</p>
              <span>*</span>
            </div>
            <BaseInput
              onChange={(e) => nameHandler(e)}
              onBlur={(e) => blurHandler(e)}
              invalid={nameDirty && nameError}
              value={name}
              name={"name"}
              type={"text"}
              placeholder={"Имя"}
            />
            <p className={nameError ? "error-text" : ""}>
              {nameDirty && nameError && nameError}
            </p>
          </div>
          <div className="body-input">
            <div className="body-input__head">
              <p>Ваша почта</p>
            </div>
            <BaseInput
              onChange={(e) => emailHandler(e)}
              onBlur={(e) => blurHandler(e)}
              invalid={emailDirty && emailError}
              value={email}
              name={"email"}
              type={"email"}
              placeholder={"E-mail"}
            />
            <p className={emailError ? "error-text" : ""}>
              {emailDirty && emailError && emailError}
            </p>
          </div>
          <div className="body-input">
            <div className="body-input__head">
              <p>Пару слов о проекте</p>
              <span>*</span>
            </div>
            <BaseInput
              onChange={(e) => sourceHandler(e)}
              onBlur={(e) => blurHandler(e)}
              invalid={sourceDirty && sourceError}
              value={source}
              name={"source"}
              type={"text"}
              placeholder={"Пару слов о проекте"}
            />
            <p className={sourceError ? "error-text" : ""}>
              {sourceDirty && sourceError && sourceError}
            </p>
          </div>
          <div className="body-checkbox">
            <input
              type="checkbox"
              onChange={(e) => checkboxHandler(e)}
              value={checkbox}
            />
            <a href="https://webteamstorm.ru/docs/agreements/">
              <h5>Соглашаюсь с правилами обрботки персональных данных </h5>
            </a>
          </div>
          <BaseButton
            styles={disable ? "button-modal disable" : "button-modal"}
            disabled={!formValid && disable}
            type="submit"
          >
            {textButton}
            {statusSend ? <Loader /> : ""}
          </BaseButton>
          <img
            className="image-exit"
            src={exit}
            alt="#"
            onClick={() => setActive(false)}
          />
        </form>
      </section>
    </div>
  );
};

export default ModalСonnection;
