import React, { useState } from "react";

import "./devProcess.scss";
import devBg1 from "../../assets/images/devBackdround1.png";
import devBg1Webp from "../../assets/images/devBackdround1.webp";
import devBg2 from "../../assets/images/devBackdround2.png";
import devBg2Webp from "../../assets/images/devBackdround2.webp";
import devBg3 from "../../assets/images/devBackdround3.png";
import devBg3Webp from "../../assets/images/devBackdround3.webp";
import devBg4 from "../../assets/images/devBackdround4.png";
import devBg4Webp from "../../assets/images/devBackdround4.webp";
import devBg5 from "../../assets/images/devBackdround5.png";
import devBg5Webp from "../../assets/images/devBackdround5.webp";
import devBg6 from "../../assets/images/devBackdround6.png";
import devBg6Webp from "../../assets/images/devBackdround6.webp";
import { useInViewLazy } from "../../hooks/useInView";

const DevProcess = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const { ref, inView} = useInViewLazy();
  function toggle(index) {
    setSelectedIndex(index);
  }

  return (
    <section className="devProcess" id={"stages"} ref={ref}>
      <div className="devProcess-box">
        <h1 className="base-title"> Как мы работаем ?</h1>
        <div className="devProcess-box__body">
          <article className="devProcessItems">
            <div className="devProcessItems-body">
              <h2
                  className="devProcessItems-body__title"
                  onClick={() => {
                    toggle(1);
                  }}
              >
                1. Обсуждение проекта
              </h2>
              <p
                  className={
                    selectedIndex == 1
                        ? "active-devProcess base-text"
                        : "base-text"
                  }
              >
                В удобном для вас формате . Вышлем и изучим заполненный бриф и
                предложим оптимальное решение под бюджет и поставленные
                бизнес-цели.
              </p>
            </div>

            {inView && (
                <picture>
                  <source srcSet={devBg1Webp} type={'image/webp'}/>
                  <img className={
                    selectedIndex == 1
                        ? "active-devProcess__images image-devProcess"
                        : "image-devProcess"
                  }
                       src={devBg1}
                       alt="#"/>
                </picture>
            )}
          </article>

          <article className="devProcessItems">
            <div className="devProcessItems-body">
              <h2
                  className="devProcessItems-body__title"
                  onClick={() => {
                    toggle(2);
                  }}
              >
                2. Исследование и анализ
              </h2>
              <p
                  className={
                    selectedIndex == 2
                        ? "active-devProcess base-text"
                        : "base-text"
                  }
              >
                Проводим изучение требований заказчика и анализ конкурентов,
                определяем целевую аудиторию и создаем уникальную концепцию
                приложения.
              </p>
            </div>

            {inView && (
                <picture>
                  <source srcSet={devBg2Webp} type={'image/webp'}/>
                  <img className={
                    selectedIndex == 2
                        ? "active-devProcess__images image-devProcess"
                        : "image-devProcess"
                  }
                       src={devBg2}
                       alt="#"/>
                </picture>
            )}
          </article>

          <article className="devProcessItems">
            <div className="devProcessItems-body">
              <h2
                  className="devProcessItems-body__title"
                  onClick={() => {
                    toggle(3);
                  }}
              >
                3. Проектирование и дизайн
              </h2>
              <p
                  className={
                    selectedIndex == 3
                        ? "active-devProcess base-text"
                        : "base-text"
                  }
              >
                Разрабатываем структуру приложения, создаем wireframes, дизайн
                пользовательского интерфейса и определяем ключевые элементы
                пользовательского опыта.
              </p>
            </div>


            {inView && (
                <picture>
                  <source srcSet={devBg3Webp} type={'image/webp'}/>
                  <img className={
                    selectedIndex == 3
                        ? "active-devProcess__images image-devProcess"
                        : "image-devProcess"
                  }
                       src={devBg3}
                       alt="#"/>
                </picture>
            )}
          </article>

          <article className="devProcessItems">
            <div className="devProcessItems-body">
              <h2
                  className="devProcessItems-body__title"
                  onClick={() => {
                    toggle(4);
                  }}
              >
                4. Разработка и тестирование
              </h2>
              <p
                  className={
                    selectedIndex == 4
                        ? "active-devProcess base-text"
                        : "base-text"
                  }
              >
                Команда программистов создает функционал приложения на основе
                утвержденных дизайн-макетов, интегрирует API и тестирует работу
                основных функций.
              </p>
            </div>

            {inView && (
                <picture>
                  <source srcSet={devBg4Webp} type={'image/webp'}/>
                  <img className={
                    selectedIndex == 4
                        ? "active-devProcess__images image-devProcess"
                        : "image-devProcess"
                  }
                       src={devBg4}
                       alt="#"/>
                </picture>
            )}
          </article>

          <article className="devProcessItems">
            <div className="devProcessItems-body">
              <h2
                  className="devProcessItems-body__title"
                  onClick={() => {
                    toggle(5);
                  }}
              >
                5. Запуск
              </h2>
              <p
                  className={
                    selectedIndex == 5
                        ? "active-devProcess base-text"
                        : "base-text"
                  }
              >
                Осуществляем развертывание на сервере, подготавливаем
                маркетинговую стратегию и запускаем приложения в магазинах.
              </p>
            </div>

            {inView && (
                <picture>
                  <source srcSet={devBg5Webp} type={'image/webp'}/>
                  <img className={
                    selectedIndex == 5
                        ? "active-devProcess__images image-devProcess"
                        : "image-devProcess"
                  }
                       src={devBg5}
                       alt="#"/>
                </picture>
            )}
          </article>

          <article className="devProcessItems">
            <div className="devProcessItems-body">
              <h2
                  className="devProcessItems-body__title"
                  onClick={() => {
                    toggle(6);
                  }}
              >
                6. Поддержка и обновление
              </h2>
              <p
                  className={
                    selectedIndex == 6
                        ? "active-devProcess base-text"
                        : "base-text"
                  }
              >
                После запуска приложения осуществляем его поддержку и постоянное
                обновление. На основе обратной связи от пользователей проводим
                улучшение функционала.
              </p>
            </div>

            {inView && (
                <picture>
                  <source srcSet={devBg6Webp} type={'image/webp'}/>
                  <img className={
                    selectedIndex == 6
                        ? "active-devProcess__images image-devProcess"
                        : "image-devProcess"
                  }
                       src={devBg6}
                       alt="#"/>
                </picture>
            )}
          </article>
        </div>
      </div>
    </section>
  );
};

export default DevProcess;
