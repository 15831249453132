import React, { useState } from "react";

import lineArrow from "../../../../assets/icons/lineCalc.svg";
import bgCalc from "../../../../assets/images/bgCulc.png";
import ModalСonnection from "../../../../components/ModalСonnection/ModalСonnection";

import "./calculator.scss";

import AppDerection from "../AppDerection/AppDerection";
import Functional from "../Functional/Functional";
import Platform from "../Platform/Platform";
import Design from "../Design/Design";
import FinalyCost from "../FinalyCost/FinalyCost";
import { useInViewLazy } from "../../../../hooks/useInView";

const Calculator = () => {
  const [modal, setModal] = useState(false);
  const { ref, inView} = useInViewLazy();
  const [indexTab, setIndexTab] = useState(1);

  const [dataCalc, setDataCalc] = useState(null);

  function submitCalc(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    setDataCalc(data);
  }

  return (
    <section className="calc" id={"calculator"} ref={ref}>
      <div className="calc-box">
        <div className="calc-box__head">
          <h1 className="calc-box__head-title">
            Калькулятор стоимости вашего приложения
          </h1>
          <img className="calc-box__head-lineArrow" src={lineArrow} alt="#" />
        </div>
        <form onSubmit={submitCalc}>
          <AppDerection indexTab={indexTab} setIndexTab={setIndexTab} />
          <Functional indexTab={indexTab} setIndexTab={setIndexTab} />
          <Platform indexTab={indexTab} setIndexTab={setIndexTab} />
          <Design indexTab={indexTab} setIndexTab={setIndexTab} />
          <FinalyCost
            indexTab={indexTab}
            setIndexTab={setIndexTab}
            setModal={setModal}
          />
        </form>
        {inView && (
            <img className="image-BgCulc" src={bgCalc} alt="#"/>
        )}
      </div>
      <ModalСonnection
          active={modal}
        setActive={setModal}
        dataCalc={dataCalc}
      />
    </section>
  );
};

export default Calculator;
