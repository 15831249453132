import cn from 'classnames';
import cls from './CasesCard.module.scss';
import React from "react";
import { useInViewLazy } from "../../../../hooks/useInView";

export const CasesCard = (props) => {
    const { className, title, image, imageWebp } = props;
    const { ref, inView} = useInViewLazy();

    return (
        <div className={cn(className, cls.card)} ref={ref}>
            <h3 className={cls.title}>{title}</h3>

            <div className={cls.image}>
                {inView && (
                    <picture>
                        <source srcSet={imageWebp} type={'image/webp'}/>
                        <img src={image} alt="#" className="image-slide"/>
                    </picture>
                )}
            </div>
        </div>
    );
}
