import React, { useState } from "react";

import Arrow from "../../../../assets/icons/arrowTab.svg";

import "./questionsItems.scss";

const QuestionsItems = ({ item }) => {
  const [openTab, setOpenTab] = useState(false);

  function toggle() {
    if (openTab == true) {
      setOpenTab(false);
    } else {
      setOpenTab(true);
    }
  }

  return (
    <article className="questionsItems" onClick={toggle}>
      <h3 className="questionsItems-title">
        {item.title}
        <svg
          width="37"
          height="37"
          viewBox="0 0 37 37"
          xmlns="http://www.w3.org/2000/svg"
          className={
            openTab
              ? "activeArrow questionsItems-arrow"
              : "questionsItems-arrow"
          }
        >
          <path
            d="M30 11.9998L18 22.9998L7.00002 11.9998C6.79282 11.8026 6.28967 11.9998 6.00002 11.9998C5.71038 11.9998 5.20723 11.8026 5.00002 11.9998C4.8997 12.0955 5.05444 12.8736 5.00002 12.9998C4.94561 13.1259 5.00002 12.8629 5.00002 12.9998C4.94561 12.8736 5.00002 13.1366 5.00002 12.9998C5.05444 13.1259 4.8997 13.904 5.00002 13.9998L18 25.9998C17.6975 25.9998 18.2167 26.2055 18 25.9998C18.3026 25.9998 18.7834 26.2055 19 25.9998L32 13.9998C32.1011 13.9039 31.9452 13.1263 32 12.9998C32 13.1372 32.0549 12.8732 32 12.9998C32 12.8624 32.0549 13.1263 32 12.9998C31.9452 12.8732 32.1011 12.0956 32 11.9998C31.7928 11.8026 31.2897 11.9998 31 11.9998C30.7104 11.9998 30.2072 11.8026 30 11.9998Z"
            fill="#9C95AE"
          />
        </svg>
      </h3>
      <p
        className={
          openTab
            ? "active-questions questionsItems-text"
            : "questionsItems-text"
        }
      >
        {item.text}
      </p>
    </article>
  );
};

export default QuestionsItems;
