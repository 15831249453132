import trackerPlanning from '../../assets/images/cases/tracker-planning.png'
import trackerPlanningWebp from '../../assets/images/cases/tracker-planning.webp'
import onlineStore from '../../assets/images/cases/online-store.png'
import onlineStoreWebp from '../../assets/images/cases/online-store.webp'
import educationMedicine from '../../assets/images/cases/education-medicine.png'
import educationMedicineWebp from '../../assets/images/cases/education-medicine.webp'
import fitnes from '../../assets/images/cases/fitnes.png'
import fitnesWebp from '../../assets/images/cases/fitnes.webp'
import game from '../../assets/images/cases/game.png'
import gameWebp from '../../assets/images/cases/game.webp'
import news from '../../assets/images/cases/news.png'
import newsWebp from '../../assets/images/cases/news.webp'
import finance from '../../assets/images/cases/finance.png'
import financeWebp from '../../assets/images/cases/finance.webp'
import sport from '../../assets/images/cases/sport.png'
import sportWebp from '../../assets/images/cases/sport.webp'
import cration from '../../assets/images/cases/сreation.png'
import сreationWebp from '../../assets/images/cases/сreation.webp'
import socialMedia from '../../assets/images/cases/social-media.png'
import socialMediaWebp from '../../assets/images/cases/social-media.webp'

export const casesMock = [
    {
        id: 1,
        title: 'Планирование, трекер',
        image: trackerPlanning,
        imageWebp: trackerPlanningWebp
    },
    {
        id: 2,
        title: 'Интернет-магазин',
        image: onlineStore,
        imageWebp: onlineStoreWebp
    },
    {
        id: 3,
        title: 'Образование, медицина',
        image: educationMedicine,
        imageWebp: educationMedicineWebp
    },
    {
        id: 4,
        title: 'Здоровье и фитнес',
        image: fitnes,
        imageWebp: fitnesWebp
    },
    {
        id: 5,
        title: 'Игры',
        image: game,
        imageWebp: gameWebp
    },
    {
        id: 6,
        title: 'Журналы, новости, справочники',
        image: news,
        imageWebp: newsWebp
    },
    {
        id: 7,
        title: 'Финансы',
        image: finance,
        imageWebp: financeWebp
    },
    {
        id: 8,
        title: 'Спорт, путешествия',
        image: sport,
        imageWebp: sportWebp
    },
    {
        id: 9,
        title: 'Творчество, развлечения',
        image: cration,
        imageWebp: сreationWebp
    },
    {
        id: 10,
        title: 'Социальные сети, знакомства',
        image: socialMedia,
        imageWebp: socialMediaWebp
    }
]
