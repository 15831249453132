import React from "react";

import PhoneCalc from "../../../../assets/images/calcPhone.png";

import BaseButton from "../../../../ui/BaseButton/BaseButton";
import { useSelector } from "react-redux";
import {
  getDevelopment,
  getDisign,
  getTask,
  getTime,
} from "../../store/calcSlice";

const FinalyCost = ({ indexTab, setIndexTab, setModal }) => {
  const task = useSelector(getTask);
  const design = useSelector(getDisign);
  const development = useSelector(getDevelopment);
  const time = useSelector(getTime);

  return (
    <section
      className={
        indexTab === 5 ? "active-calcTab calc-box__body" : "calc-box__body"
      }
    >
      <div className="calc-box__body-head">
        <h2>Предварительный расчет:</h2>
      </div>
      <div className="calc-box__body-container">
        <div className="container-finaly">
          <div className="container-finaly__price">
            <span>Консультация</span>
            <span>бесплатно</span>
          </div>

          <div className="container-finaly__price">
            <span>ТЗ</span>
            <span>от {task} тыс. руб.</span>
          </div>

          <div className="container-finaly__price">
            <span>Дизайн</span>
            <span>от {design} тыс. руб. </span>
          </div>

          <div className="container-finaly__price">
            <span>Разработка</span>
            <span>от {development} тыс. руб.</span>
          </div>

          <div className="container-finaly__price">
            <p>Итого </p>
            <p>от {task + design + development} тыс. руб. </p>
          </div>

          <div className="container-finaly__price">
            <p>Срок работы</p>
            <p>от {time} дней</p>
          </div>
        </div>
        <img className="image-phone" src={PhoneCalc} alt="#" />
      </div>
      <div className="calc-box__body-finalyBottom">
        <BaseButton
          styles={"bottom-calc__btn"}
          type="submit"
          onClick={() => {
            setModal(true);
          }}
        >
          Оформить заказ
        </BaseButton>
      </div>
    </section>
  );
};

export default FinalyCost;
