import Home from "./pages/Home/Home";

import "./App.scss";

import ModalСonnection from "./components/ModalPresent/ModalPresent";
import React, {useEffect, useState} from "react";

function App() {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setModal(true);
    }, 25000)
  }, []);

  return (
    <div className="App">
      <Home />

      <ModalСonnection active={modal} setActive={setModal} />
    </div>
  );
}

export default App;
