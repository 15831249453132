import React from "react";

import "./checkboxCircle.css";

const CheckboxCircle = ({ styles, id, name, value, text, ...props }) => {
  return (
    <div className="checkboxCircle-box">
      <input
        type="radio"
        className="checkboxCircle "
        id={id}
        name={name}
        value={value}
        {...props}
      />
      <label className="checkboxCircle-label" htmlFor={id}>
        {text}
      </label>
    </div>
  );
};

export default CheckboxCircle;
