import { useInView } from 'react-intersection-observer';

export const useInViewLazy = () => {
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return {
        ref,
        inView
    }
}
